// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-posts-randos-immortalized-deed-md": () => import("./../../../src/posts/randos/immortalized-deed.md" /* webpackChunkName: "component---src-posts-randos-immortalized-deed-md" */),
  "component---src-posts-randos-on-becoming-a-youtuber-md": () => import("./../../../src/posts/randos/on-becoming-a-youtuber.md" /* webpackChunkName: "component---src-posts-randos-on-becoming-a-youtuber-md" */),
  "component---src-posts-randos-some-typescript-tricks-md": () => import("./../../../src/posts/randos/some-typescript-tricks.md" /* webpackChunkName: "component---src-posts-randos-some-typescript-tricks-md" */),
  "component---src-posts-randos-why-is-this-website-is-made-using-gatsby-md": () => import("./../../../src/posts/randos/why-is-this-website-is-made-using-gatsby.md" /* webpackChunkName: "component---src-posts-randos-why-is-this-website-is-made-using-gatsby-md" */),
  "component---src-posts-randos-year-2021-md": () => import("./../../../src/posts/randos/year-2021.md" /* webpackChunkName: "component---src-posts-randos-year-2021-md" */),
  "component---src-posts-randos-year-2022-so-far-md": () => import("./../../../src/posts/randos/year-2022-so-far.md" /* webpackChunkName: "component---src-posts-randos-year-2022-so-far-md" */)
}

